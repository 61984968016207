<template>
  <div class="wap-debt">
    <headerBar
      :title="$t('cai-wu-bao-biao-0')"
      @back="back"
      right-icon="filter-o"
      @detail="toDetail"
    ></headerBar>

    <div class="report-box">
      <div class="box">
        <div class="label">{{ $t('dai-dao-zhang-jin-e') }}</div>
        <div class="value">${{  '0.00' }}</div>
      </div>
      <div class="box">
        <div class="label">{{ $t('zong-xiao-shou-e') }}</div>
        <div class="value">${{  '0.00' }}</div>
      </div>
      <div class="box">
        <div class="label">{{ $t('zong-li-run') }}</div>
        <div class="value">${{ TotalProfitSum.toFixed(2) }}</div>
      </div>
      <div class="box">
        <div class="label">{{ $t('zong-ding-dan-0') }}</div>
        <div class="value">{{ TotalOrdersSum }}</div>
      </div>
      <div class="box">
        <div class="label">{{ $t('qu-xiao-ding-dan') }}</div>
        <div class="value">{{ CancelledSum }}</div>
      </div>
      <div class="box">
        <div class="label">{{ $t('tui-kuan-ding-dan-0') }}</div>
        <div class="value">{{RefundedSum}}</div>
      </div>
    </div>

    <div class="report-list">
      <List
        v-if="logList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="
          isInit && logList.length == 0 ? '' : $t('common.noMore')
        "
        @load="onLoad"
      >
        <div class="list" v-for="(item, i) in logList" :key="i">
          <div class="line flex-center-between">
            <div class="flex-center">
              <img :src="reportUrl1" alt="" />
              {{ $t('ri-qi-0') }}
              {{ item.Date }}
            </div>
            <div class="blue">
              {{ $t('li-run-1') }}
              {{ item.TotalProfit }}
            </div>
          </div>
          <div class="line flex-center-between">
            <div class="flex-center">
              <img :src="reportUrl2" alt="" />
              {{ $t('zong-ding-dan-1') }}
              {{ item.TotalOrders }}
            </div>
          </div>
          <div class="line flex-center-between">
            <div class="flex-center">
              <img :src="reportUrl3" alt="" />
              {{ $t('qu-xiao-ding-dan-0') }}
              {{ item.Cancelled }}
            </div>
          </div>
          <div class="line flex-center-between">
            <div class="flex-center">
              <img :src="reportUrl3" alt="" />
              {{ $t('tui-kuan-ding-dan-1') }}
              {{ item.Refunded }}
            </div>
          </div>
        </div>
      </List>
      <Empty v-if="logList.length === 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>

    <Popup v-model="showPopup" position="bottom">
      <Picker
        :columns="typeList"
        show-toolbar
        value-key="label"
        @cancel="cancel"
        @confirm="chooseType"
      ></Picker>
    </Popup>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { Popup, Button, List, Picker, Empty } from 'vant'
import { shopReport } from '@/api/shop'
import moment from 'moment'
export default {
  components: {
    headerBar,
    Button,
    Popup,
    List,
    Empty,
    Picker
  },
  data() {
    return {
      reportUrl1: require('@/assets/imgs/icon-report1.png'),
      reportUrl2: require('@/assets/imgs/icon-report2.png'),
      reportUrl3: require('@/assets/imgs/icon-report3.png'),
      reportUrl4: require('@/assets/imgs/icon-report4.png'),
      logList: [],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      orderData: {},
      reportData: {},
      typeList: [
        {
          value: 5,
          label: this.$t('quan-bu')
        },
        {
          value: 1,
          label: this.$t('jin-ri')
        },
        {
          value: 0,
          label: this.$t('zuo-ri')
        },
        {
          value: 2,
          label: this.$t('ben-zhou')
        },
        {
          value: 3,
          label: this.$t('ben-yue')
        },
        {
          value: 4,
          label: this.$t('ben-nian')
        }
      ],
      showPopup: false,
      form: {
        type: 5
      },
      TotalOrdersSum: 0,
      TotalProfitSum: 0,
      CancelledSum: 0,
      RefundedSum: 0,
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      let start = ''
      let end = ''
      switch(this.form.type) {
        case 0 : end = moment().subtract(1, 'days').format('YYYY-MM-DD'); start = moment().subtract(1, 'days').format('YYYY-MM-DD'); break;
        case 1 : end = moment().format('YYYY-MM-DD'); start = moment().format('YYYY-MM-DD'); break;
        case 2 : end = moment().endOf('weeks').format('YYYY-MM-DD'); start = moment().startOf('weeks').format('YYYY-MM-DD'); break;
        case 3 : end = moment().endOf('month').format('YYYY-MM-DD'); start = moment().startOf('month').format('YYYY-MM-DD'); break;
        case 4 : end = moment().endOf('year').format('YYYY-MM-DD'); start = moment().startOf('year').format('YYYY-MM-DD'); break;
      }
      shopReport({
        current: this.page.page,
        pageSize: this.page.limit,
        StartDate: start ? `${start} 00:00:00` : '',
        EndDate: end ? `${end} 23:59:59` : '',
      })
        .then((res) => {
          let list = res.data || []
          if (isAdd) {
            this.logList = this.logList.concat(list)
          } else {
            this.logList = list
          }
          this.TotalOrdersSum = this.logList.reduce((prev, cur) => prev + cur.TotalOrders, 0)
          this.TotalProfitSum = this.logList.reduce((prev, cur) => prev + cur.TotalProfit, 0)
          this.CancelledSum = this.logList.reduce((prev, cur) => prev + cur.Cancelled, 0)
          this.RefundedSum = this.logList.reduce((prev, cur) => prev + cur.Refunded, 0)
          this.finished = true
          // this.totalPage = Math.ceil(this.logList.length)
          // if (this.totalPage <= this.page.page) {
          //   this.finished = true
          // }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    toDetail() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    chooseType(e) {
      this.showPopup = false
      this.form.type = e.value
      this.init()
    },
    getTypeName(type) {
      let index = this.typeList.findIndex((v) => {
        return v.value == type
      })
      if (index > -1) {
        return this.typeList[index].label
      } else {
        return ''
      }
    }
  }
}
</script>